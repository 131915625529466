<template>
    <m-main-layout>
        <el-button size="small" type="primary" slot="right-btn" @click="$router.push({path:'/rule/group/list'})">规则列表
        </el-button>
        <div class="page-content-main">
            <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top"
                     style="width:600px;">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="formData.name" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="是否可用" prop="status">
                    <el-switch
                        v-model="formData.status"
                        active-color="#13ce66"
                        :active-value="1"
                        :inactive-value="0"
                        active-text="启用"
                        inactive-text="禁用">
                    </el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">确定</el-button>
                    <el-button type="" @click="$router.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "add",
    data() {
        return {
            formData: {
                name: '',
                state: 1,
            },
            rules: {
                name: [
                    {required: true, message: '角色名称', trigger: 'blur'},
                    {min: 1, max: 50, message: '请输入1-50位字符', trigger: 'blur'}
                ],
                state: [
                    {required: true, message: '状态必选', trigger: 'blur'},
                ]
            },
        };
    },
    created() {

    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.$api.ruleGroupAdd(_this.formData,
                        function (res) {
                            _this.$router.replace({path: '/rule/group'})
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>
